<template>
  <div class="container">
    <div v-if="client">
      <div class="activityLogView">
        <div class="row mb-3">
          <div class="col my-auto">
            <h5 class="mb-0 client-tab-heading">Activity Log</h5>
          </div>
          <div class="col-5 text-end">
            <div class="dropdown">
              <button
                class="btn btn-light btn-sm dropdown-toggle"
                type="button"
                id="filteringDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="far fa-filter me-1"></i>
                <span class="text-capitalize me-2">{{
                  filtering | formatFilteringLabel | formatSubjectType
                }}</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="filteringDropdown">
                <button
                  v-for="type in distinctActivityTypes"
                  class="dropdown-item"
                  type="button"
                  @click="filterBy(type)"
                >
                  {{ type | formatSubjectType }}
                </button>
              </div>
              <button
                v-if="filtering"
                @click="filterBy(null)"
                class="btn btn-sm btn-light ms-1"
              >
                <i class="far fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!--  -->
        <hr class="my-3" />

        <div class="row text-center" v-if="!loading && activities.length === 0">
          <div class="col">
            <h5>There are no activities for this client</h5>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style="border-top: 0px">Type</th>
                        <th scope="col" style="border-top: 0px">Subject ID</th>
                        <th scope="col" style="border-top: 0px">Action</th>
                        <th scope="col" style="border-top: 0px">User</th>
                        <th scope="col" style="border-top: 0px">Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loading">
                        <td colspan="5">
                          <busy visible="true" />
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="activity in activities"
                        class="cursor-pointer"
                      >
                        <td>{{ activity.subject_type | formatSubjectType }}</td>
                        <td>{{ activity.subject_id }}</td>
                        <td class="text-capitalize">
                          {{ activity.description }}
                        </td>
                        <td>
                          {{
                            activity.causer ? activity.causer.name : "System"
                          }}
                        </td>
                        <td>{{ activity.created_at | formatDate }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col my-auto">
                      <button
                        class="btn btn-sm btn-light"
                        @click="fetchActivityLog(pagination.first_page_url)"
                        :disabled="!pagination.first_page_url"
                      >
                        First Page
                      </button>

                      <button
                        class="btn btn-sm btn-light ms-3"
                        @click="fetchActivityLog(pagination.prev_page_url)"
                        :disabled="!pagination.prev_page_url"
                      >
                        Previous
                      </button>
                    </div>
                    <div class="col my-auto text-center">
                      <p class="mb-0">
                        Page {{ pagination.current_page }} of
                        {{ pagination.last_page }}
                      </p>
                    </div>
                    <div class="col my-auto text-end">
                      <button
                        class="btn btn-sm btn-light cursor-pointer me-3"
                        @click="fetchActivityLog(pagination.next_page_url)"
                        :disabled="!pagination.next_page_url"
                      >
                        Next
                      </button>

                      <button
                        class="btn btn-sm btn-light cursor-pointer"
                        @click="fetchActivityLog(pagination.last_page_url)"
                        :disabled="!pagination.last_page_url"
                      >
                        Last Page
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client: null,
      loading: true,
      pagination: {},
      filtering: null,
      activities: [],
      originalActivities: null,
    };
  },
  methods: {
    fetchActivityLog(paginationUrl) {
      this.loading = true;
      var url = paginationUrl
        ? paginationUrl
        : process.env.VUE_APP_API_URL +
          "/clients/api/" +
          this.client.id +
          "/activity-log";

      if (this.filtering) {
        url = this.updateQueryStringParameter(url, "filter", this.filtering);
      }

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.activities = data.data;
        this.originalActivities = this.originalActivities ?? this.activities;
        this.loading = false;
      });
    },
    filterBy(field) {
      this.filtering = field;
      this.fetchActivityLog();
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchActivityLog();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    formatFilteringLabel(str) {
      if (!str) {
        return "Filter by Type";
      } else {
        return "Type: " + str.replace(/_/g, " ");
      }
    },
    formatSubjectType(str) {
      var ret = str.replace("App\\Models\\", "");

      if (ret !== "Client") {
        ret = ret.replace("Client", "");
      }

      return ret;
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    distinctActivityTypes() {
      var ret = [];

      if (this.originalActivities) {
        for (var i = 0; i < this.originalActivities.length; i++) {
          if (ret.indexOf(this.originalActivities[i].subject_type) === -1) {
            ret.push(this.originalActivities[i].subject_type);
          }
        }
      }

      return ret;
    },
  },
  components: {},
};
</script>

<style>
.card {
  box-shadow: none;
}
</style>
